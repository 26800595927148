
/**
 * ¡IMPORTANTE!
 *
 * Este archivo es autogenerado por el script "version.js", no
 * modificarlo manualmente, ni pushearlo al repo.
 *
 */

export const VERSION = {
    "dirty": false,
    "raw": "174a3eef6",
    "hash": "174a3eef6",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "174a3eef6",
    "semverString": null,
    "version": "2.22.1"
};

